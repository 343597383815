import { lazy } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import AuthLayout from './layouts/AuthLayout';
import DashboardLayout from './layouts/DashboardLayout';
import AclGuard from './utils/AclGuard';
import AuthGuard from './utils/AuthGuard';
import ErrorView from './pages/ErrorView';
import GodView from './pages/GodView';
import SignInContainer from './views/auth/containers/SignInContainer';
import SignUpContainer from './views/auth/containers/SignUpContainer';
import ForgotPasswordContainer from './views/auth/containers/ForgotPasswordContainer';
import PhioAccessList from 'views/workflow/PhioAccessList';
import DeclineForEngageList from 'views/workflow/DeclineForEngageList';
import DischargedFromEngageList from 'views/workflow/DischargedFromEngageList';
import TriageContainer from 'access/triage/TriageContainer';
import PatientContainer from 'access/patient/PatientContainer';
import EditPatient from 'access/patient/EditPatientContainer';
import AddPatient from 'access/patient/AddPatient';
import UploadPatients from 'access/patient/UploadPatients';
import AboutThisPortal from 'access/AboutThisPortal';
import PermissionUpgrade from 'views/auth/PermissionUpgrade';
import DashboardIndexView from 'views/DashboardIndexView';

const Adherence = lazy(() => import('views/adherence/containers'));
const PatientList = lazy(() => import('views/patient/containers/PatientListContainer'));
const PatientInfo = lazy(() => import('views/patient/containers/PatientContainer'));
const DailyAssessment = lazy(() => import('views/reports/containers/DailyAssessmentContainer'));
const WeeklyAssessment = lazy(() => import('views/reports/containers/WeeklyAssessmentContainer'));
const SkippedFeedback = lazy(() => import('views/reports/containers/SkippedFeedbackContainer'));
const SessionFeedback = lazy(() => import('views/reports/containers/SessionFeedbackContainer'));
const Support = lazy(() => import('views/support/containers/SupportContainer'));
const Suitability = lazy(() => import('views/triage/containers/SuitabilityContainer'));
const Reports = lazy(() => import('views/reports/containers'));
const TriageInfo = lazy(() => import('views/triage/containers/TriageInfoContainer'));
const KitRequest = lazy(() => import('views/kit/containers/KitRequestContainer'));
const AuditLogs = lazy(() => import('views/audit/containers/AuditLog'));
const Users = lazy(() => import('views/users/containers/UsersContainer'));
const AddUser = lazy(() => import('views/users/containers/CreateUserContainer'));
const EditUser = lazy(() => import('views/users/containers/EditUserContainer'));
const ProgrammeList = lazy(() => import('views/RehabProgramme/RehabProgrammeList'));
const MiView = lazy(() => import('views/MI'));
const RehabProgrammeInfo = lazy(() => import('views/RehabProgramme/RehabProgramme'));
const RehabProgrammeEditor = lazy(() => import('views/RehabProgramme/ProgrammeEditor'));
const CollectManagement = lazy(() => import('views/collect'));

const App = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Navigate to="/signin" />} />
                <Route path="*" element={<Navigate to="/404" replace />} />
                <Route path="/404" element={<ErrorView />} />
                <Route path="/godmode" element={<GodView />} />

                {/* Auth Layout (Sign In, Sign Up, etc) */}
                <Route element={<AuthLayout />}>
                    <Route path="/signin" element={<SignInContainer />} />
                    <Route path="/signup" element={<SignUpContainer />} />
                    <Route path="/forgotpassword" element={<ForgotPasswordContainer />} />
                </Route>

                {/* Dashboard Layout (all protected by auth & role-based ACL) */}
                <Route
                    path="/dashboard"
                    element={
                        <AuthGuard>
                            <DashboardLayout />
                        </AuthGuard>
                    }
                >
                    {/* super, client_clinician_super, client_clinician, internal_clinician */}
                    <Route element={<AclGuard acl={['engage_management']} />}>
                        <Route path="adherence" element={<Adherence />} />
                        <Route path="patients" element={<PatientList />} />
                        <Route path="patient/:id" element={<PatientInfo />} />
                        <Route path="daily_assessment" element={<DailyAssessment />} />
                        <Route path="weekly_assessment" element={<WeeklyAssessment />} />
                        <Route path="skipped_feedback" element={<SkippedFeedback />} />
                        <Route path="session_feedback" element={<SessionFeedback />} />
                        <Route path="support">
                            <Route path="" element={<Support />} />
                            <Route path=":uid" element={<Support />} />
                        </Route>
                    </Route>

                    {/* access pages: super */}
                    <Route path="access" element={<AclGuard acl={['access_management']} />}>
                        <Route path="triage" element={<TriageContainer />} />
                        <Route path="patient">
                            <Route path="" element={<PatientContainer />} />
                            <Route path=":id" element={<EditPatient />} />
                            <Route path="create" element={<AddPatient />} />
                            <Route path="upload" element={<UploadPatients />} />
                        </Route>
                        <Route path="about-this-portal" element={<AboutThisPortal />} />
                    </Route>

                    {/* super, client_clinician_super, internal_clinician */}
                    <Route element={<AclGuard acl={['engage_management']} />}>
                        <Route path="suitable-for-treatment">
                            <Route path="" element={<Suitability />} />
                            <Route path="triage/:id" element={<TriageInfo />} />
                        </Route>
                    </Route>

                    {/* super, client_clinician_super, internal_clinician_super */}
                    <Route element={<AclGuard acl={['user_management']} />}>
                        <Route path="audit-logs" element={<AuditLogs />} />
                    </Route>

                    {/* super, internal_clinician_super, internal_clinician */}
                    <Route
                        element={
                            <AclGuard acl={['internal_clinical_services', 'customer_support']} />
                        }
                    >
                        <Route path="kit-request" element={<KitRequest />} />
                    </Route>

                    {/* super, client_clinician_super */}
                    <Route
                        element={<AclGuard acl={['super_user_management', 'user_management']} />}
                    >
                        <Route path="users" element={<Users />} />
                        <Route path="user/create" element={<AddUser />} />
                        <Route path="user/edit/:id" element={<EditUser />} />
                    </Route>

                    {/* super */}
                    <Route element={<AclGuard acl={['product_configuration']} />}>
                        <Route path="programmes" element={<ProgrammeList />} />
                        <Route
                            path="rehab-programme/:rehabProgrammeId"
                            element={<RehabProgrammeInfo />}
                        />
                        <Route path="rehab-programme-editor" element={<RehabProgrammeEditor />} />
                    </Route>

                    {/** phio_work_flow_super users */}
                    <Route element={<AclGuard acl={['workflow']} />}>
                        <Route path="phio_access" element={<PhioAccessList />} />
                        <Route path="declined_for_engage" element={<DeclineForEngageList />} />
                        <Route
                            path="discharged_from_engage"
                            element={<DischargedFromEngageList />}
                        />
                    </Route>
                    {/** Management information */}
                    <Route element={<AclGuard acl={['management_information']} />}>
                        <Route path="mi" element={<MiView />} />
                        <Route path="reports" element={<Reports />} />
                    </Route>
                    {/** Collect Management */}
                    <Route element={<AclGuard acl={['collect_management']} />}>
                        <Route path="collect" element={<CollectManagement />} />
                    </Route>
                    <Route index element={<DashboardIndexView />} />
                </Route>
                <Route
                    path="/permission-upgrade"
                    element={
                        <AuthGuard>
                            <PermissionUpgrade />
                        </AuthGuard>
                    }
                />
            </Routes>
        </BrowserRouter>
    );
};

export default App;
